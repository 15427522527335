// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

// ** Axios Imports
import axios from "axios"

// const baseUrl = `${process.env.REACT_APP_API_BASE_URL}api/`
const baseAuthUrl = `${process.env.REACT_APP_API_AUTH_BASE_URL}`
export const getData = createAsyncThunk(
  "appInvoice/getData",
  async (params) => {
    const response = await axios.get(
      `${baseAuthUrl}api/v1/company/${params.id}/invoices`
    )
    return {
      params,
      data: response.data.data,
      allData: response.data.data,
      totalPages: response.data.data.length / params.perPage
    }
  }
)

export const deleteInvoice = createAsyncThunk(
  "appInvoice/deleteInvoice",
  async (id, { dispatch, getState }) => {
    await axios.delete("/apps/invoice/delete", { id })
    await dispatch(getData(getState().invoice.params))
    return id
  }
)

export const clearData = createAsyncThunk("appInvoice/clearData", () => {
  return null
})

export const appInvoiceSlice = createSlice({
  name: "appInvoice",
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedInvoice: null,
    totalBalance: 0,
    pastDue: 0,
    openInvoices: 0,
    dueDate: ""
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.allData = action.payload.allData
        state.total = action.payload.totalPages
        state.params = action.payload.params

        state.totalBalance = action.payload.data.reduce(
          (previousBalance, item) => previousBalance + item.balance,
          0
        )

        state.openInvoices = action.payload.data.reduce(
          (previousBalance, item) =>
            previousBalance + (item.balance > 0 ? 1 : 0),
          0
        )

        if (state.openInvoices > 0) {
          state.dueDate = action.payload.data
            .filter((item) => {
              return item.balance > 0
            })
            .sort((a, b) => a.dueDate > b.dueDate)[0].dueDate
        }
      })
      .addCase(clearData.fulfilled, (state, action) => {
        state.selectedInvoice = action.payload
        state.data = []
        state.total = 1
        state.allData = []
      })
  }
})

export default appInvoiceSlice.reducer
