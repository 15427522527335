import mock from "../mock"

// ** Utils
import { paginateArray } from "../utils"

const data = {
  auditlogs: [
    {
      id: 4987,
      issuedDate: "13 Dec 2019",
      client: {
        address: "7777 Mendez Plains",
        company: "Hall-Robbins PLC",
        companyEmail: "tom@devnostic.com",
        country: "USA",
        contact: "(616) 865-4180",
        name: "Devnostic"
      },
      service: "Software Development",
      entityType: "Agreement",
      action: "Page View",
      actionTakenBy: "Tom Brown",
      total: 3428,
      avatar: "",
      auditlogStatus: "Paid",
      balance: "$724",
      dueDate: "23 Apr 2019"
    }
  ]
}

// ------------------------------------------------
// GET: Return Invoice List
// ------------------------------------------------
mock.onGet("/apps/auditlog/auditlogs").reply((config) => {
  // eslint-disable-next-line object-curly-newline
  const {
    q = "",
    perPage = 10,
    page = 1,
    status = null,
    sort,
    sortColumn
  } = config
  /* eslint-enable */

  const dataAsc = data.auditlogs.sort((a, b) => {
    if (a[sortColumn]) {
      return a[sortColumn] < b[sortColumn] ? -1 : 1
    } else {
      const splitColumn = sortColumn.split(".")
      const columnA = a[splitColumn[0]][splitColumn[1]]
      const columnB = b[splitColumn[0]][splitColumn[1]]
      return columnA < columnB ? -1 : 1
    }
  })

  const dataToFilter = sort === "asc" ? dataAsc : dataAsc.reverse()

  const queryLowered = q.toLowerCase()
  const filteredData = dataToFilter.filter((auditlog) => {
    if (String("paid").includes(queryLowered) && auditlog.balance === 0) {
      return auditlog.balance === 0
    } else {
      /* eslint-disable operator-linebreak, implicit-arrow-linebreak */
      return (
        (auditlog.client.companyEmail.toLowerCase().includes(queryLowered) ||
          auditlog.client.name.toLowerCase().includes(queryLowered) ||
          String(auditlog.id).toLowerCase().includes(queryLowered) ||
          String(auditlog.total).toLowerCase().includes(queryLowered) ||
          String(auditlog.balance).toLowerCase().includes(queryLowered) ||
          auditlog.dueDate.toLowerCase().includes(queryLowered)) &&
        auditlog.auditlogStatus.toLowerCase() ===
          (status.toLowerCase() || auditlog.auditlogStatus.toLowerCase())
      )
    }
  })
  /* eslint-enable  */

  return [
    200,
    {
      allData: data.auditlogs,
      total: filteredData.length,
      auditlogs:
        filteredData.length <= perPage
          ? filteredData
          : paginateArray(filteredData, perPage, page)
    }
  ]
})

// ------------------------------------------------
// GET: Return Single Invoice
// ------------------------------------------------
mock.onGet(/\/api\/auditlog\/auditlogs\/\d+/).reply((config) => {
  // // Get event id from URL
  const auditlogId = Number(
    config.url.substring(config.url.lastIndexOf("/") + 1)
  )

  const auditlogIndex = data.auditlogs.findIndex((e) => e.id === auditlogId)
  const responseData = {
    auditlog: data.auditlogs[auditlogIndex],
    paymentDetails: {
      totalDue: "$12,110.55",
      bankName: "American Bank",
      country: "United States",
      iban: "ETD95476213874685",
      swiftCode: "BR91905"
    }
  }
  return [200, responseData]
})

// ------------------------------------------------
// DELETE: Deletes Invoice
// ------------------------------------------------
mock.onDelete("/apps/auditlog/delete").reply((config) => {
  // Get auditlog id from URL
  let auditlogId = config.id

  // Convert Id to number
  auditlogId = Number(auditlogId)

  const auditlogIndex = data.auditlogs.findIndex((t) => t.id === auditlogId)
  data.auditlogs.splice(auditlogIndex, 1)

  return [200]
})

// ------------------------------------------------
// GET: Return Clients
// ------------------------------------------------
mock.onGet("/api/auditlog/clients").reply(() => {
  const clients = data.auditlogs.map((auditlog) => auditlog.client)
  return [200, clients.slice(0, 5)]
})
