// ** React Imports
import { Link, useNavigate, useParams } from "react-router-dom"
import { useSkin } from "@hooks/useSkin"
import { useEffect, useState } from "react"

// ** Icons Imports
import { ChevronLeft } from "react-feather"

// ** Reactstrap Imports
import {
  Card,
  CardBody,
  CardTitle,
  CardText,
  Form,
  Label,
  Input,
  Button,
  ListGroup,
  ListGroupItem
} from "reactstrap"

import themeConfig from "@configs/themeConfig"
import { getUserData } from "@utils"
import AutoComplete from "@components/autocomplete"

import { switchCompany } from "./store"
import { clearData as contactClearData } from "../../apps/user/store"
import { clearData as projClearData } from "../../apps/project/store"
import { clearData as ticketClearData } from "../../apps/tickets/store"
import { clearData as invClearData } from "../../apps/invoice/store"
import { clearData as agreementClearData } from "../../apps/agreement/store"
import { clearData as documentationClearData } from "../../apps/documentation/store"
import { clearData as companyClearData } from "../../apps/company/store"

// ** Styles
import "@styles/react/pages/page-authentication.scss"
import { useDispatch, useSelector } from "react-redux"

const CompanySelect = () => {
  const authStore = useSelector((state) => state.authentication)

  const { skin } = useSkin()
  const user = getUserData()
  const { id } = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [changedCompany, setChangedCompany] = useState(false)

  useEffect(() => {
    if (changedCompany && authStore.selectedCompany) {
      navigate("/dashboard")
    }
  }, [authStore.selectedCompany, changedCompany])

  useEffect(() => {
    if (id) {
      try {
        dispatch(
          switchCompany({
            companyId: id,
            userId: user.id
          })
        ).then((res) => {
          if (res.type === "appLoginUser/switchCompany/fulfilled") {
            dispatch(agreementClearData())
            dispatch(contactClearData())
            dispatch(projClearData())
            dispatch(ticketClearData())
            dispatch(invClearData())
            dispatch(documentationClearData())
            dispatch(companyClearData())
            setChangedCompany(true)
          }
        })
      } catch (error) {
        console.log(error)
      }
    }
  }, [id])

  return (
    <div className="auth-wrapper auth-basic px-2">
      <div className="auth-inner auth-inner-md my-2">
        <Card className="mb-0">
          <CardBody>
            <Link
              className="brand-logo"
              to="/"
              onClick={(e) => e.preventDefault()}
            >
              <img
                src={
                  skin === "light"
                    ? themeConfig.app.appLogoImage
                    : themeConfig.app.darkLogoImage
                }
                style={{ maxWidth: "200px" }}
                alt="logo"
              />
            </Link>
            {!id && (
              <div>
                <CardTitle tag="h4" className="mb-1">
                  Choose the company you would like to manage.
                </CardTitle>{" "}
                <AutoComplete
                  className="form-control"
                  filterKey="title"
                  suggestionLimit={10}
                  suggestions={authStore.companyFilter}
                  placeholder="Search Companies..."
                />
                {authStore.selectedCompanies && (
                  <p className="fw-bolder pt-50 mt-2">
                    {authStore.selectedCompanies.length} Recently Viewed
                  </p>
                )}
                <ListGroup flush className="mb-2">
                  {authStore.selectedCompanies &&
                    authStore.selectedCompanies.map((item) => {
                      return (
                        <ListGroupItem
                          key={item.companyId}
                          className="d-flex align-items-start border-0 px-0"
                        >
                          <div className="me-1">
                            <Link
                              tag="h5"
                              to={`/company-select/${item.companyId}`}
                            >
                              {item.companyName}
                            </Link>
                          </div>
                        </ListGroupItem>
                      )
                    })}
                </ListGroup>
              </div>
            )}
            {id && (
              <div className="text-center">
                Loading your company....please wait.
              </div>
            )}
            <p className="text-center mt-2">
              <Link to="/login">
                <ChevronLeft className="rotate-rtl me-25" size={14} />
                <span className="align-middle">Back to login</span>
              </Link>
            </p>
          </CardBody>
        </Card>
      </div>
    </div>
  )
}

export default CompanySelect
