import mock from "./mock"

import "./jwt"
import "./pages/faq"
import "./apps/userList"
import "./apps/companyList"
import "./apps/kanban"
import "./apps/agreementList"
import "./pages/profile"
import "./apps/invoice"
import "./apps/eCommerce"
import "./apps/calendar"
import "./apps/auditlog"
import "./apps/project"
import "./pages/blog-data"
import "./tables/datatables"
import "./pages/pricing-data"
import "./navbar/navbarSearch"
import "./pages/knowledge-base"
import "./apps/permissionsList"
import "./cards/card-analytics"
import "./cards/card-statistics"
import "./pages/account-settings"
import "./autoComplete/autoComplete"

mock.onAny().passThrough()
