// ** Icons Import
import { Heart } from "react-feather"

const Footer = () => {
  return (
    <p className="clearfix mb-0">
      <span className="float-md-start d-block d-md-inline-block mt-25">
        Copyright © {new Date().getFullYear()}{" "}
        <a
          href="https://www.ntiva.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          Ntiva
        </a>
        <span className="d-none d-sm-inline-block">, All Rights Reserved</span>
      </span>
      <span className="float-md-end d-none d-md-block">
        Designed and Developed by{" "}
        <a href="https://www.devnostic.com/" target="_blank">
          DEVNOSTIC
        </a>
      </span>
    </p>
  )
}

export default Footer
