import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
// ** Axios Imports
import axios from "axios"

const baseUrl = `${process.env.REACT_APP_API_BASE_URL}api/`
const baseAuthUrl = `${process.env.REACT_APP_API_AUTH_BASE_URL}`
export const getAllData = createAsyncThunk(
  "appUsersList/getAllData",
  async () => {
    const response = await axios.get(`${baseUrl}v1/users`)
    return response.data
  }
)
export const getUserData = createAsyncThunk(
  "appUsersList/getUserData",
  async (params) => {
    const response = await axios.get(
      `${baseUrl}v1/users?sort=${params.sort}&&sortColumn=${params.sortColumn}`
    )
    const total = response.data.data.length
    const pageNum = parseInt(params.page) - 1
    const skipData = pageNum * params.perPage
    const size = params.perPage
    const data = response.data.data
    const users = []
    for (let index = 0; index < data.length; index++) {
      const user = data[index]
      if (index >= skipData && index < skipData + size) {
        users.push(user)
      }
    }
    return {
      params,
      data: users,
      totalPages: total,
      allData: data
    }
  }
)

export const getData = createAsyncThunk(
  "appUsersList/getData",
  async (params) => {
    const response = await axios.get(
      `${baseUrl}v1/users?sort=${params.sort}&&sortColumn=${params.sortColumn}`
    )
    const total = response.data.data.length
    const pageNum = parseInt(params.page) - 1
    const skipData = pageNum * params.perPage
    const size = params.perPage
    const data = response.data.data
    const users = []
    for (let index = 0; index < data.length; index++) {
      const user = data[index]
      if (index >= skipData && index < skipData + size) {
        users.push(user)
      }
    }

    return {
      params,
      data: users,
      totalPages: total,
      allData: data
    }
  }
)
export const inviteUserByEmail = createAsyncThunk(
  "appUsersList/inviteUserByEmail",
  async (payload) => {
    const response = await axios.post(`${baseUrl}v1/users/invite`, payload)
    if (response) return response
  }
)

export const performActionOnUser = createAsyncThunk(
  "appUsersList/performActionOnUser",
  async (payload) => {
    const response = await axios.post(`${baseUrl}users/actions`, payload)
    return response
  }
)

export const changeUserRole = createAsyncThunk(
  "appUsersList/changeUserRole",
  async (payload) => {
    const response = await axios.put(
      `${baseAuthUrl}api/v1/user/changeRole`,
      payload
    )
    return response
  }
)
export const loading = createAsyncThunk("appUsersList/loading", (payload) => {
  return payload
})

export const appUsersListSlice = createSlice({
  name: "appUsersList",
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    isLoading: false
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUserData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
        state.allData = action.payload.allData
        state.isLoading = false
      })
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload
        state.isLoading = false
      })
      .addCase(changeUserRole.fulfilled, (state) => {
        state.isLoading = false
      })
      .addCase(loading.fulfilled, (state, action) => {
        state.isLoading = action.payload
      })
  }
})

export default appUsersListSlice.reducer
