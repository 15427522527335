import mock from "../mock"

export const searchArr = [
  {
    groupTitle: "Companies",
    searchLimit: 4,
    data: [
      {
        id: 1,
        target: "devnostic",
        isBookmarked: false,
        title: "Devnostic",
        icon: "Home",
        link: "/companies/view/devnostic"
      }
    ]
  },
  {
    groupTitle: "Contacts",
    searchLimit: 4,
    data: [
      {
        title: "Tom Brown",
        company: "Devnostic"
      }
    ]
  },
  {
    groupTitle: "Agreements",
    searchLimit: 4,
    data: [
      {
        title: "Agreement 1",
        company: "Devnostic"
      }
    ]
  }
]

// GET Search Data
mock.onGet("/api/main-search/data").reply(() => {
  return [200, { searchArr }]
})

// GET Search Data & Bookmarks
mock.onGet("/api/bookmarks/data").reply(() => {
  const bookmarks = searchArr[0].data.filter((item) => item.isBookmarked)
  const suggestions = searchArr[0].data
  return [200, { suggestions, bookmarks }]
})

// POST Update isBookmarked
mock.onPost("/api/bookmarks/update").reply((config) => {
  const { id } = JSON.parse(config.data)

  const obj = searchArr[0].data.find((item) => item.id === id)

  Object.assign(obj, { isBookmarked: !obj.isBookmarked })

  return [200]
})
