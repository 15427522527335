// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

// ** Axios Imports
import axios from "axios"

const baseUrl = `${process.env.REACT_APP_API_BASE_URL}api/`
export const getAllData = createAsyncThunk(
  "appProducts/getAllData",
  async () => {
    const response = await axios.get(`${baseUrl}v1/ntiva/products`)
    return response.data
  }
)

export const getData = createAsyncThunk(
  "appProducts/getData",
  async (params) => {
    const response = await axios.get(
      `${baseUrl}v1/ntiva/products?sort=${params.sort}&&sortColumn=${params.sortColumn}`,
      params
    )
    const data = response.data.data
    const total = response.data.data.length
    const pageNum = parseInt(params.page) - 1
    const skipData = pageNum * params.perPage
    const size = params.perPage
    const products = []
    for (let index = 0; index < data.length; index++) {
      const product = data[index]
      if (index >= skipData && index < skipData + size) {
        products.push(product)
      }
    }
    return {
      params,
      data: products,
      totalPages: total,
      allData: data
    }
  }
)

export const getProduct = createAsyncThunk(
  "appProducts/getProduct",
  async (id) => {
    const response = await axios.get(`${baseUrl}v1/ntiva/products/${id}`, {
      id
    })
    return response.data.data
  }
)

export const addProduct = createAsyncThunk(
  "appProducts/addProduct",
  async (product, { dispatch, getState }) => {
    await axios.post("/apps/product/add-product", product)
    await dispatch(getData(getState().products.params))
    await dispatch(getAllData())
    return product
  }
)

export const deleteProduct = createAsyncThunk(
  "appProducts/deleteProduct",
  async (id, { dispatch, getState }) => {
    await axios.delete("/apps/product/delete", { id })
    await dispatch(getData(getState().products.params))
    await dispatch(getAllData())
    return id
  }
)

export const agreementEnabler = createAsyncThunk(
  "appUsers/agreementEnabler",
  async (configuration, { dispatch }) => {
    const response = await axios.post(
      `${baseUrl}v1/configuration`,
      configuration
    )
    await dispatch(getProduct(configuration.id))
    return response
  }
)

export const getAddProducts = createAsyncThunk(
  "appUsers/getAddProducts",
  async (id) => {
    const response = await axios.get(
      `${baseUrl}v1/ntiva/contradictory/products/${id}`
    )
    return response.data
  }
)

export const postAddProducts = createAsyncThunk(
  "appUsers/postAddProducts",
  async (data, { dispatch }) => {
    const response = await axios.post(
      `${baseUrl}v1/ntiva/contradictory/products/update`,
      data
    )
    await dispatch(getProduct(data.id))
    return response.data
  }
)
export const clearData = createAsyncThunk("appProducts/clearData", () => {
  return null
})
export const loading = createAsyncThunk("appProducts/loading", (payload) => {
  return payload
})

export const appProductsSlice = createSlice({
  name: "appProducts",
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedProduct: null,
    addProducts: [],
    isLoading: false
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload.data
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
        state.allData = action.payload.allData
      })
      .addCase(getProduct.fulfilled, (state, action) => {
        state.selectedProduct = action.payload
      })
      .addCase(getAddProducts.fulfilled, (state, action) => {
        state.addProducts = action.payload.data
      })
      .addCase(clearData.fulfilled, (state, action) => {
        state.selectedProduct = action.payload
      })
      .addCase(loading.fulfilled, (state, action) => {
        state.isLoading = action.payload
      })
  }
})

export default appProductsSlice.reducer
