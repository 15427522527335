// You can customize the template with the help of this file

//Template config options
//'https://res.cloudinary.com/ntiva/image/upload/v1656577339/Ntiva-Logo-Success-Tag-Blue-FINAL_z2nql2.webp'
//appLogoImage: require('../assets/images/logo/Ntiva-Logo-Success-Tag-Blue-FINAL.webp').default
// appLogoImage: require('https://res.cloudinary.com/ntiva/image/upload/v1656577339/Ntiva-Logo-Success-Tag-Blue-FINAL_z2nql2.webp').default

const themeConfig = {
  app: {
    appName: "Ntiva",
    appLogoImage: require("../assets/images/logo/logo_tagline@4x_1.svg")
      .default,
    darkLogoImage: require("../assets/images/logo/icon_white.svg").default,
    mobileLogoImage: require("../assets/images/logo/icon_default@4x.png")
      .default,
    darkMobileLogoImage:
      require("../assets/images/logo/icon_white_orange@4x.png").default
  },
  layout: {
    isRTL: false,
    skin: "light", // light, dark, bordered, semi-dark
    routerTransition: "fadeIn", // fadeIn, fadeInLeft, zoomIn, none or check this for more transition https://animate.style/
    type: "vertical", // vertical, horizontal
    contentWidth: "boxed", // full, boxed
    menu: {
      isHidden: false,
      isCollapsed: true
    },
    navbar: {
      // ? For horizontal menu, navbar type will work for navMenu type
      type: "sticky", // static , sticky , floating, hidden
      backgroundColor: "white" // BS color options [primary, success, etc]
    },
    footer: {
      type: "static" // static, sticky, hidden
    },
    customizer: false,
    scrollTop: true, // Enable scroll to top button,
    toastPosition: "top-right" // top-left, top-center, top-right, bottom-left, bottom-center, bottom-right
  }
}

export default themeConfig
