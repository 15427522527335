import axios from "axios"
import jwtDefaultConfig from "./jwtDefaultConfig"
import { getUserData } from "@utils"

export default class JwtService {
  constructor(jwtOverrideConfig) {
    // ** Merge default and override config
    this.jwtConfig = { ...jwtDefaultConfig, ...jwtOverrideConfig }

    // ** Track refresh token requests and subscribers
    this.isAlreadyFetchingAccessToken = false
    this.subscribers = []

    // ** Bind instance methods to ensure 'this' context
    this.attachTokenToRequest = this.attachTokenToRequest.bind(this)
    this.handleErrorResponse = this.handleErrorResponse.bind(this)

    // ** Set up interceptors
    axios.interceptors.request.use(
      (config) => this.attachTokenToRequest(config),
      (error) => Promise.reject(error)
    )
    axios.interceptors.response.use(
      (response) => response,
      (error) => this.handleErrorResponse(error)
    )
  }

  // ** Attach access token and user data to request headers
  attachTokenToRequest(config) {
    const accessToken = this.getToken()
    const user = getUserData()

    if (accessToken) {
      config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
    }
    config.headers.BearerUser = JSON.stringify(user)
    return config
  }

  // ** Handle errors and refresh token on 401
  async handleErrorResponse(error) {
    const { config, response } = error
    const originalRequest = config

    if (response && response.status === 401) {
      if (!this.isAlreadyFetchingAccessToken) {
        this.isAlreadyFetchingAccessToken = true

        try {
          const refreshResponse = await this.refreshToken()
          this.isAlreadyFetchingAccessToken = false

          const { accessToken, refreshToken } = refreshResponse.data
          this.setToken(accessToken)
          this.setRefreshToken(refreshToken)
          this.onAccessTokenFetched(accessToken)
        } catch (refreshError) {
          this.isAlreadyFetchingAccessToken = false
          localStorage.clear()
          window.location.replace(`${process.env.REACT_APP_BASE_URL}login`)
          return Promise.reject(refreshError)
        }
      }

      return new Promise((resolve) => {
        this.addSubscriber((token) => {
          originalRequest.headers.Authorization = `${this.jwtConfig.tokenType} ${token}`
          resolve(axios(originalRequest))
        })
      })
    }
    return Promise.reject(error)
  }

  // ** Call all subscribers when a new access token is fetched
  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter((callback) =>
      callback(accessToken)
    )
  }

  // ** Add a new subscriber for token refresh
  addSubscriber(callback) {
    this.subscribers.push(callback)
  }

  // ** Helper to get access token
  getToken() {
    return localStorage.getItem(this.jwtConfig.storageTokenKeyName)
  }

  // ** Helper to get refresh token
  getRefreshToken() {
    return localStorage.getItem(this.jwtConfig.storageRefreshTokenKeyName)
  }

  // ** Helper to set access token
  setToken(value) {
    localStorage.setItem(this.jwtConfig.storageTokenKeyName, value)
  }

  // ** Helper to set refresh token
  setRefreshToken(value) {
    localStorage.setItem(this.jwtConfig.storageRefreshTokenKeyName, value)
  }

  // ** Login endpoint with error handling
  login(...args) {
    return axios.post(this.jwtConfig.loginEndpoint, ...args).catch((error) => {
      console.error("Login error:", error)
      throw error
    })
  }

  // ** Register endpoint with error handling
  register(...args) {
    return axios
      .post(this.jwtConfig.registerEndpoint, ...args)
      .catch((error) => {
        console.error("Registration error:", error)
        throw error
      })
  }

  // ** Refresh token endpoint with error handling
  refreshToken() {
    return axios
      .post(this.jwtConfig.refreshEndpoint, {
        refreshToken: this.getRefreshToken()
      })
      .catch((error) => {
        console.error("Refresh token error:", error)
        throw error
      })
  }
}
