import mock from "../mock"
const data = {
  faqData: {
    // payment
    usermgr: {
      icon: "User",
      title: "User Management",
      subtitle: "Managing Your Employees",
      qandA: [
        {
          question: "How do I remove someone from an agreement?",
          ans: "Click on the Agreements tab on the left hand navigation, then click users. Once you have found the user you want to remove, simply toggle the switch at the end of the row."
        }
      ]
    }
  }
}

mock.onGet("/faq/data").reply((config) => {
  const { q = "" } = config.params
  const queryLowered = q.toLowerCase()

  const filteredData = {}

  Object.entries(data.faqData).forEach((entry) => {
    const [categoryName, categoryObj] = entry
    const filteredQAndAOfCategory = categoryObj.qandA.filter((qAndAObj) => {
      return qAndAObj.question.toLowerCase().includes(queryLowered)
    })
    filteredData[categoryName] = {
      ...categoryObj,
      qandA: filteredQAndAOfCategory.length ? filteredQAndAOfCategory : []
    }
  })

  return [200, filteredData]
})
