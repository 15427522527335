// ** React Imports
import { Link } from "react-router-dom"
import { useState } from "react"

// ** Custom Components
import Avatar from "@components/avatar"
import AutoComplete from "@components/autocomplete"

// ** Store & Actions
import { useDispatch, useSelector } from "react-redux"
import { handleLogout } from "@store/authentication"

// ** Third Party Components
import { User, Settings, Power, RefreshCw } from "react-feather"

// ** Reactstrap Imports
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  ListGroup,
  ListGroupItem
} from "reactstrap"

// ** Default Avatar Image
import defaultAvatar from "@src/assets/images/icons/avatar.png"

const UserDropdown = () => {
  // ** Store Vars
  const dispatch = useDispatch()
  const [show, setShow] = useState(false)

  // ** State

  const authStore = useSelector((state) => state.authentication)

  //** Vars
  const { uploadProfile } = useSelector((state) => state.accountSetting)
  const userData = JSON.parse(localStorage.getItem("userData"))
  const userImage = (userData && userData.avatar) || defaultAvatar
  const userAvatar = uploadProfile || userImage
  return (
    <UncontrolledDropdown tag="li" className="dropdown-user nav-item mb-1">
      <DropdownToggle
        href="/"
        tag="a"
        className="nav-link dropdown-user-link"
        onClick={(e) => e.preventDefault()}
      >
        <Avatar img={userAvatar} imgHeight="34" imgWidth="34" status="online" />
        <div className="user-nav d-flex">
          <span className="user-name fw-bold">
            {(userData && userData["username"]) || "John Doe"}
          </span>
          <span className="user-status">
            {(userData && userData.email) || "john.doe@email.com"}
          </span>
        </div>
      </DropdownToggle>
      <DropdownMenu end>
        <DropdownItem tag={Link} to="#" onClick={() => setShow(true)}>
          <RefreshCw size={14} className="me-75" />
          <span className="align-middle">Switch Company</span>
        </DropdownItem>
        <DropdownItem tag={Link} to="/pages/account-settings">
          <Settings size={14} className="me-75" />
          <span className="align-middle">Settings</span>
        </DropdownItem>
        <DropdownItem
          tag={Link}
          to="/login"
          onClick={() => dispatch(handleLogout())}
        >
          <Power size={14} className="me-75" />
          <span className="align-middle">Logout</span>
        </DropdownItem>
      </DropdownMenu>

      <Modal
        isOpen={show}
        toggle={() => setShow(!show)}
        className="modal-dialog-centered modal-lg"
      >
        <ModalHeader
          className="bg-transparent"
          toggle={() => setShow(!show)}
        ></ModalHeader>
        <ModalBody className="px-sm-5 mx-50 pb-4">
          <h1 className="text-center mb-1">Choose Company</h1>
          <p className="text-center">
            Search for a company or select from your most recently viewed.
          </p>
          <AutoComplete
            className="form-control"
            filterKey="title"
            suggestionLimit={10}
            suggestions={authStore.companyFilter}
            placeholder="Search Companies..."
          />

          {authStore.selectedCompanies && (
            <p className="fw-bolder pt-50 mt-2">
              {authStore.selectedCompanies.length} Recently Viewed
            </p>
          )}
          <ListGroup flush className="mb-2">
            {authStore.selectedCompanies &&
              authStore.selectedCompanies.map((item) => {
                return (
                  <ListGroupItem
                    key={item.companyId}
                    className="d-flex align-items-start border-0 px-0"
                  >
                    <div className="me-1">
                      <Link tag="h5" to={`/company-select/${item.companyId}`}>
                        {item.companyName}
                      </Link>
                    </div>
                  </ListGroupItem>
                )
              })}
          </ListGroup>
        </ModalBody>
      </Modal>
    </UncontrolledDropdown>
  )
}

export default UserDropdown
