import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
// import request from "../../../../redux/request"
import axios from "axios"

const baseUrl = `${process.env.REACT_APP_API_BASE_URL}api/`
const baseAuthUrl = `${process.env.REACT_APP_API_AUTH_BASE_URL}`
export const loginUser = createAsyncThunk(
  "appLoginUser/loginUser",
  async (data) => {
    const response = await axios.post(`${baseUrl}v1/login`, data)
    if (response.status === 200) {
      localStorage.setItem("uuid", response.data.uuid)
      if (response.data.defaultCompany)
        localStorage.setItem(
          "selectedCompany",
          JSON.stringify(response.data.defaultCompany)
        )
      localStorage.setItem(
        "selectedCompanies",
        JSON.stringify(response.data.selectedCompanies)
      )
      localStorage.setItem(
        "companyFilter",
        JSON.stringify(
          response.data.selectedCompanies.map((item) => {
            return {
              link: `/company-select/${item.companyId}`,
              value: item.companyId,
              title: item.companyName
            }
          })
        )
      )
      return response
    }
  }
)
export const forgotPassword = createAsyncThunk(
  "appLoginUser/forgotPassword",
  async (data) => {
    const response = await axios.post(`${baseUrl}v1/forgot/password`, data)
    if (response.status === 200) {
      return response
    }
  }
)
export const resetPassword = createAsyncThunk(
  "appLoginUser/resetPassword",
  async (data) => {
    const response = await axios.post(
      `${baseAuthUrl}api/v1/password/reset`,
      data
    )
    if (response.status === 200) {
      return response
    }
  }
)
export const signupUser = createAsyncThunk(
  "appLoginUser/signupUser",
  async (data) => {
    const response = await axios.post(`${baseUrl}users/signup`, data)
    if (response.status === 200) {
      return response
    }
  }
)
export const selfRegister = createAsyncThunk(
  "appLoginUser/selfRegister",
  async (email) => {
    const response = await axios.get(
      `${baseAuthUrl}api/v1/selfregister?email=${email}`
    )
    return response.data
  }
)
export const resendMfa = createAsyncThunk(
  "appLoginUser/resendMfa",
  async (mfaData) => {
    const response = await axios.post(`${baseUrl}v1/resend/mfa`, mfaData)
    return response
  }
)

export const switchCompany = createAsyncThunk(
  "appLoginUser/switchCompany",
  async (companyData) => {
    const response = await axios.put(
      `${baseUrl}users/setdefaultcompany/${companyData.userId}`,
      companyData
    )
    return response
  }
)

export const getUserPhoneEmail = createAsyncThunk(
  "appLoginUser/getUserPhoneEmail",
  async (id) => {
    const response = await axios.get(`${baseUrl}v1/user/phone/${id}`)
    return response
  }
)
export const confirmMfa = createAsyncThunk(
  "appLoginUser/confirmMfa",
  async (confirmData) => {
    const response = await axios.post(`${baseUrl}v1/mfa_confirm`, confirmData)
    if (response.status === 200) {
      const { id, name, email, firstName, lastName, avatar } =
        response.data.userData
      const userData = {
        id,
        fullName: name,
        username: `${firstName} ${lastName}`,
        avatar,
        email,
        role: response.data.role,
        ability: localStorage.getItem("abilities"),
        extras: { ecommercecartitemscount: 5 },
        accesstoken: response.data.token,
        refreshtoken: response.data.token
      }
      localStorage.setItem("refreshToken", response.data.token)
      localStorage.setItem("accessToken", response.data.token)
      localStorage.setItem("userData", JSON.stringify(userData))
      localStorage.setItem("role", response.data.role)
      localStorage.setItem("CW_ID", response.data.userData.cW_ID)

      return response
    }
  }
)
export const userSetup = createAsyncThunk(
  "appLoginUser/userSetup",
  async (body) => {
    const response = await axios.post(`${baseUrl}users/setup`, body)
    return response
  }
)

export const appLoginUserSlice = createSlice({
  name: "appLoginUser",
  initialState: {
    user: [],
    userdata: [],
    loginData: [],
    selectedCompanies: [],
    selectedCompany: null,
    companyFilter: {},
    phoneEmail: {}
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.fulfilled, (state, action) => {
        state.user = action.payload.data
        state.selectedCompanies = action.payload.data.selectedCompanies
        if (action.payload.data.defaultCompany)
          state.selectedCompany = action.payload.data.defaultCompany
        state.companyFilter = action.payload.data.selectedCompanies.map(
          (item) => {
            return {
              link: `/company-select/${item.companyId}`,
              value: item.companyId,
              title: item.companyName
            }
          }
        )
      })
      .addCase(switchCompany.fulfilled, (state, action) => {
        const sComp = {
          companyId: parseInt(action.payload.data.id),
          companyName: action.payload.data.name,
          isDefault: true,
          companyCWId: parseInt(action.payload.data.cW_ID)
        }
        state.selectedCompany = sComp
        if (sComp)
          localStorage.setItem("selectedCompany", JSON.stringify(sComp))
      })
      .addCase(signupUser.fulfilled, (state, action) => {
        state.userdata = action.payload
      })
      .addCase(confirmMfa.fulfilled, (state, action) => {
        state.loginData = action.payload.data
      })
      .addCase(getUserPhoneEmail.fulfilled, (state, action) => {
        state.phoneEmail = action.payload.data
      })
  }
})

export default appLoginUserSlice.reducer
