// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

// ** Axios Imports
import axios from "axios"

const baseAuthUrl = `${process.env.REACT_APP_API_AUTH_BASE_URL}`

export const getData = createAsyncThunk("appAlerts/getData", async (params) => {
  const response = await axios.get(
    `${baseAuthUrl}api/v1/alerts?Enabled=1`,
    params
  )
  const data = response.data.data
  const total = response.data.data.length
  const pageNum = parseInt(params.page) - 1
  const skipData = pageNum * params.perPage
  const size = params.perPage
  const alerts = []
  for (let index = 0; index < data.length; index++) {
    const alert = data[index]
    if (index >= skipData && index < skipData + size) {
      alerts.push(alert)
    }
  }
  return {
    params,
    data: alerts,
    totalPages: total,
    allData: data
  }
})

export const getAlert = createAsyncThunk("appAlerts/getAlert", async (id) => {
  const response = await axios.get(`${baseAuthUrl}api/v1/alerts/${id}`, { id })
  return response.data.data
})

export const addAlert = createAsyncThunk(
  "appAlerts/addAlert",
  async (alert, { dispatch }) => {
    await axios.post(`${baseAuthUrl}api/v1/alerts/`, alert)
    await dispatch(getData(alert.params))
    return alert
  }
)

export const updateAlert = createAsyncThunk(
  "appAlerts/updateAlert",
  async (alert, { dispatch }) => {
    await axios.put(`${baseAuthUrl}api/v1/alerts/`, alert)
    await dispatch(getData(alert.params))
    return alert
  }
)

export const deleteAlert = createAsyncThunk(
  "appAlerts/deleteAlert",
  async (alert, { dispatch }) => {
    await axios.put(`${baseAuthUrl}api/v1/alerts/delete`, alert)
    await dispatch(getData(alert.params))
    return alert
  }
)

export const clearData = createAsyncThunk("appAlerts/clearData", () => {
  return null
})

export const loading = createAsyncThunk("appAlerts/loading", (payload) => {
  return payload
})

export const appAlertsSlice = createSlice({
  name: "appAlerts",
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedAlert: null,
    isLoading: false
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
        state.allData = action.payload.allData
      })
      .addCase(getAlert.fulfilled, (state, action) => {
        state.selectedAlert = action.payload
      })
      .addCase(clearData.fulfilled, (state, action) => {
        state.selectedAlert = action.payload
        state.data = []
        state.total = 1
        state.allData = []
      })
      .addCase(loading.fulfilled, (state, action) => {
        state.isLoading = action.payload
      })
  }
})

export default appAlertsSlice.reducer
