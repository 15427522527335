import { lazy } from "react"
import { Navigate } from "react-router-dom"
import CompanySelect from "../../views/pages/authentication/CompanySelect"

const Faq = lazy(() => import("../../views/pages/faq"))
const ApiKey = lazy(() => import("../../views/pages/api-key"))
const Profile = lazy(() => import("../../views/pages/profile"))
const Pricing = lazy(() => import("../../views/pages/pricing"))
const License = lazy(() => import("../../views/pages/license"))
const Error = lazy(() => import("../../views/pages/misc/Error"))
const BlogList = lazy(() => import("../../views/pages/blog/list"))
const BlogEdit = lazy(() => import("../../views/pages/blog/edit"))
const BlogDetails = lazy(() => import("../../views/pages/blog/details"))
const ComingSoon = lazy(() => import("../../views/pages/misc/ComingSoon"))
const ModalExamples = lazy(() => import("../../views/pages/modal-examples"))
const Maintenance = lazy(() => import("../../views/pages/misc/Maintenance"))
const AccountSettings = lazy(() => import("../../views/pages/account-settings"))
const SystemSettings = lazy(() => import("../../views/pages/system-settings"))
const NotAuthorized = lazy(() => import("../../views/pages/misc/NotAuthorized"))
const KnowledgeBase = lazy(() =>
  import("../../views/pages/knowledge-base/KnowledgeBase")
)
const KnowledgeBaseCategory = lazy(() =>
  import("../../views/pages/knowledge-base/KnowledgeBaseCategory")
)
const KBCategoryQuestion = lazy(() =>
  import("../../views/pages/knowledge-base/KnowledgeBaseCategoryQuestion")
)

const PagesRoutes = [
  {
    path: "/pages/profile",
    element: <Profile />
  },
  {
    path: "/pages/faq",
    element: <Faq />,
    meta: {
      action: "read",
      resource: "PUBLIC"
    }
  },
  {
    path: "/pages/knowledge-base",
    element: <KnowledgeBase />
  },
  {
    path: "/pages/knowledge-base/:category",
    element: <KnowledgeBaseCategory />
  },
  {
    path: "/pages/knowledge-base/:category/:question",
    element: <KBCategoryQuestion />
  },
  {
    path: "/pages/account-settings",
    element: <AccountSettings />,
    meta: {
      action: "read",
      resource: "PUBLIC"
    }
  },
  {
    path: "/pages/account-settings/:uuid",
    element: <AccountSettings />,
    meta: {
      action: "read",
      resource: "PUBLIC"
    }
  },
  {
    path: "/pages/system-settings",
    element: <SystemSettings />
  },
  {
    path: "/company-select",
    element: <CompanySelect />,
    meta: {
      layout: "blank",
      publicRoute: true,
      restricted: false
    }
  },
  {
    path: "/company-select/:id",
    element: <CompanySelect />,
    meta: {
      layout: "blank",
      publicRoute: true,
      restricted: false
    }
  },
  {
    path: "/pages/license",
    element: <License />
  },
  {
    path: "/pages/api-key",
    element: <ApiKey />
  },
  {
    path: "/pages/modal-examples",
    element: <ModalExamples />
  },
  {
    path: "/pages/blog/list",
    element: <BlogList />
  },
  {
    path: "/pages/blog/detail/:id",
    element: <BlogDetails />
  },
  {
    path: "/pages/blog/detail",
    element: <Navigate to="/pages/blog/detail/1" />
  },
  {
    path: "/pages/blog/edit/:id",
    element: <BlogEdit />
  },
  {
    path: "/pages/blog/edit",
    element: <Navigate to="/pages/blog/edit/1" />
  },
  {
    path: "/pages/pricing",
    element: <Pricing />
  },
  {
    path: "/misc/coming-soon",
    element: <ComingSoon />,
    meta: {
      publicRoute: true,
      layout: "blank"
    }
  },
  {
    path: "/misc/not-authorized",
    element: <NotAuthorized />,
    meta: {
      publicRoute: true,
      layout: "blank"
    }
  },
  {
    path: "/misc/maintenance",
    element: <Maintenance />,
    meta: {
      publicRoute: true,
      layout: "blank"
    }
  },
  {
    path: "/misc/error",
    element: <Error />,
    meta: {
      publicRoute: true,
      layout: "blank"
    }
  }
]

export default PagesRoutes
