// ** React Imports
import { Fragment } from "react"

// ** Custom Components
import NavbarUser from "./NavbarUser"
import NavbarBookmarks from "./NavbarBookmarks"
import NavbarSearch from "./NavbarSearch"

import themeConfig from "@configs/themeConfig"

const ThemeNavbar = (props) => {
  // ** Props
  const { skin, setSkin, setMenuVisibility } = props

  return (
    <Fragment>
      <div className="bookmark-wrapper d-flex align-items-center">
        <NavbarBookmarks setMenuVisibility={setMenuVisibility} />
      </div>
      <div className="mobile-branding d-sm-none">
        <img
          src={
            skin === "light"
              ? themeConfig.app.mobileLogoImage
              : themeConfig.app.darkMobileLogoImage
          }
          style={{ maxHeight: "30px" }}
          alt="logo"
        />
      </div>
      <NavbarSearch />
      <NavbarUser skin={skin} setSkin={setSkin} />
    </Fragment>
  )
}

export default ThemeNavbar
