import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios"
const baseUrl = `${process.env.REACT_APP_API_BASE_URL}api/`

export const uploadImage = createAsyncThunk(
  "appAccountSetting/uploadImage",
  async (formData) => {
    const response = await axios.post(
      `${baseUrl}v1/ntiva/user/avatar`,
      formData
    )
    if (response.data) {
      return response.data
    }
  }
)

export const resetPassword = createAsyncThunk(
  "appLoginUser/resetPassword",
  async (data) => {
    const response = await axios.post(`${baseUrl}v1/reset/password`, data)
    return response
  }
)

export const updataProfile = createAsyncThunk(
  "appLoginUser/updataProfile",
  async (data) => {
    const response = await axios.post(`${baseUrl}users/profile`, data)
    return response
  }
)

const appAccountSettingSlice = createSlice({
  name: "appAccountSetting",
  initialState: {
    uploadProfile: ""
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(uploadImage.fulfilled, (state, action) => {
      state.uploadProfile = action.payload.data.avatar
    })
  }
})

export default appAccountSettingSlice.reducer
