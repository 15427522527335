// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

// ** Axios Imports
import axios from "axios"
import moment from "moment/moment"

// const baseUrl = `${process.env.REACT_APP_API_BASE_URL}api/`
const baseAuthUrl = `${process.env.REACT_APP_API_AUTH_BASE_URL}`

const getPermission = (item) => {
  const abils = JSON.parse(localStorage.getItem("abilities"))
  const hasAbility = abils.filter((abil) => {
    return (
      (abil.action === item.action && abil.subject === item.resource) ||
      (abil.action === "manage" && abil.subject === "all")
    )
  })
  return hasAbility.length > 0
}

export const getAllData = createAsyncThunk(
  "appTickets/getAllData",
  async (id) => {
    // const response = await axios.get(`${baseUrl}v1/company/${id}/tickets`)
    const response = await axios.get(
      `${baseAuthUrl}api/v1/company/${id}/tickets?conditions=company/id=${id}%20and%20board/id in (64, 45)%20and%20closedFlag=false`
    )
    return response.data
  }
)

export const getData = createAsyncThunk(
  "appTickets/getData",
  async (params) => {
    // const response = await axios.get(`${baseUrl}v1/company/${params.id}/tickets?sort=${params.sort}&&sortColumn=${params.sortColumn}`, params)
    const idLookup = isNaN(params.q) ? "" : ` id = ${params.q} or `
    const searchConditions =
      params.q === ""
        ? ""
        : ` and ( ${idLookup} summary contains '${params.q}' or contact/name contains '${params.q}' or status/name contains '${params.q}' or priority/name contains '${params.q}' )`
    let dateFilter = ""
    if (params.picker) {
      dateFilter += ` and (_info/dateEntered >= [${moment(
        params.picker[0]
      ).format("YYYY-MM-DDT00:00:00")}Z])`
    }
    if (params.pickerTo) {
      dateFilter += ` and (_info/dateEntered <= [${moment(
        params.pickerTo[0]
      ).format("YYYY-MM-DDT23:59:59")}Z])`
    }
    let userFilter = ""
    if (!getPermission({ action: "manage", resource: "TICKETS" })) {
      userFilter = ` and contact/id = ${localStorage.getItem("CW_ID")} `
    }
    let orderBy = ""
    if (params.sortColumn) {
      orderBy = `&orderby=${params.sortColumn} ${params.sort}`
    }
    const response = await axios.get(
      encodeURI(
        `${baseAuthUrl}api/v1/company/${params.id}/tickets?conditions=company/id=${params.id} and (board/id=45 or board/id=60 or (board/id=64 and source/id=6)) and closedFlag=${params.isClosed} ${searchConditions} ${dateFilter} ${userFilter}${orderBy}`
      ),
      params
    )
    const data = response.data.data
    const total = response.data.data.length
    const pageNum = parseInt(params.page) - 1
    const skipData = pageNum * params.perPage
    const size = params.perPage
    const tickets = []
    for (let index = 0; index < data.length; index++) {
      const ticket = data[index]
      if (index >= skipData && index < skipData + size) {
        tickets.push(ticket)
      }
    }
    return {
      params,
      data: tickets,
      totalPages: total,
      allData: data
    }
  }
)

export const getTicket = createAsyncThunk(
  "appTickets/getTicket",
  async (id) => {
    const response = await axios.get(
      `${baseAuthUrl}api/v1/tickets/${id}?includes=notes`,
      { id }
    )
    return response.data.data
  }
)

export const addTicket = createAsyncThunk(
  "appTickets/addTicket",
  async (ticket, { dispatch }) => {
    await axios.post(`${baseAuthUrl}api/v1/tickets/`, ticket)
    await dispatch(getData(ticket.params))
    return ticket
  }
)

export const addAttachments = createAsyncThunk(
  "appTickets/addAttachments",
  async (ticket, { dispatch }) => {
    await axios.patch(`${baseAuthUrl}api/v1/tickets/attachment`, ticket)
    await dispatch(getTicket(ticket.id))
    return ticket
  }
)

export const addTicketNote = createAsyncThunk(
  "appTickets/addTicketNote",
  async (ticket, { dispatch }) => {
    await axios.post(
      `${baseAuthUrl}api/v1/tickets/${ticket.ticketId}/note`,
      ticket
    )
    await dispatch(getTicket(ticket.ticketId))
    return ticket
  }
)

export const uploadTicketAttachment = (formData) => {
  return axios.post(`${baseAuthUrl}api/v1/tickets/attachment`, formData)
}

export const clearData = createAsyncThunk("appTickets/clearData", () => {
  return null
})
export const loading = createAsyncThunk("appTickets/loading", (payload) => {
  return payload
})

export const appTicketsSlice = createSlice({
  name: "appTickets",
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedTicket: null,
    addTickets: [],
    isLoading: false,
    openTickets: [],
    actionTickets: []
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload.data
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
        state.allData = action.payload.allData

        state.actionTickets = action.payload.data.filter((element) => {
          return element.status.name.includes("Waiting on Customer")
        })

        state.openTickets = action.payload.data.filter((element) => {
          return !element.closedFlag
        })
      })
      .addCase(getTicket.fulfilled, (state, action) => {
        state.selectedTicket = action.payload
      })
      .addCase(clearData.fulfilled, (state, action) => {
        state.selectedTicket = action.payload
        state.data = []
        state.total = 1
        state.allData = []
      })
      .addCase(loading.fulfilled, (state, action) => {
        state.isLoading = action.payload
      })
  }
})

export default appTicketsSlice.reducer
