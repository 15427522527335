// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

// ** Axios Imports
import axios from "axios"

const baseUrl = `${process.env.REACT_APP_API_BASE_URL}api/`
const baseAuthUrl = `${process.env.REACT_APP_API_AUTH_BASE_URL}`

export const getAllData = createAsyncThunk(
  "appProposals/getAllData",
  async () => {
    const response = await axios.get(`${baseUrl}v1/ntiva/proposals`)
    return response.data
  }
)

export const getData = createAsyncThunk(
  "appProposals/getData",
  async (params) => {
    const response = await axios.get(
      encodeURI(`${baseAuthUrl}api/v1/company/${params.id}/proposals`),
      params
    )
    const data = response.data.data
    const total = response.data.data.length
    const pageNum = parseInt(params.page) - 1
    const skipData = pageNum * params.perPage
    const size = params.perPage
    const proposals = []
    for (let index = 0; index < data.length; index++) {
      const proposal = data[index]
      if (index >= skipData && index < skipData + size) {
        proposals.push(proposal)
      }
    }
    return {
      params,
      data: proposals,
      totalPages: total,
      allData: data
    }
  }
)

export const clearData = createAsyncThunk("appProposals/clearData", () => {
  return null
})

export const loading = createAsyncThunk("appProposals/loading", (payload) => {
  return payload
})

export const appProposalsSlice = createSlice({
  name: "appProposals",
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedProposal: null,
    isLoading: false
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload.data
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
        state.allData = action.payload.allData
      })
      .addCase(clearData.fulfilled, (state, action) => {
        state.selectedProposal = action.payload
        state.data = []
        state.total = 1
        state.allData = []
        state.isLoading = false
      })
      .addCase(loading.fulfilled, (state, action) => {
        state.isLoading = action.payload
      })
  }
})

export default appProposalsSlice.reducer
