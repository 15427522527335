import { useContext } from "react"
import { DefaultRoute } from "../router/routes"

import { AbilityContext } from "@src/utility/context/Can"

// ** Checks if an object is empty (returns boolean)
export const isObjEmpty = (obj) => Object.keys(obj).length === 0

// ** Returns K format from a number
export const kFormatter = (num) =>
  num > 999 ? `${(num / 1000).toFixed(1)}k` : num
export const pctFormatter = (num, len = 2, suffix = " %") =>
  `${(num * 100).toFixed(len)}${suffix}`

// ** Converts HTML to string
export const htmlToString = (html) => html.replace(/<\/?[^>]+(>|$)/g, "")

// ** Checks if the passed date is today
const isToday = (date) => {
  const today = new Date()
  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  )
}

export const checkAbility = (action, resource) => {
  const ability = useContext(AbilityContext)

  return ability.can(action, resource) || ability.can("manage", "all")
}

export const formatPhoneNumber = (e) => {
  let formattedNumber

  if (e) {
    const { length } = e // Filter non numbers
    const regex = () => e.replace(/[^0-9\.]+/g, "") // Set area code with parenthesis around it
    const areaCode = () => `${regex().slice(0, 3)}-`

    // Set formatting for first six digits
    const firstSix = () => `${areaCode()}${regex().slice(3, 6)}`

    // Dynamic trail as user types
    const trailer = (start) => `${regex().slice(start, regex().length)}`
    if (length < 3) {
      // First 3 digits
      formattedNumber = regex()
    } else if (length === 4) {
      // After area code
      formattedNumber = `${areaCode()} ${trailer(3)}`
    } else if (length === 5) {
      // When deleting digits inside parenthesis
      formattedNumber = `${areaCode().replace(")", "")}`
    } else if (length > 5 && length < 9) {
      // Before dash
      formattedNumber = `${areaCode()} ${trailer(3)}`
    } else if (length >= 10) {
      // After dash
      formattedNumber = `${firstSix()}-${trailer(6)}`
    }
    return formattedNumber
  } else {
    return ""
  }
}

/**
 ** Format and return date in Humanize format
 ** Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 ** Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (
  value,
  formatting = { month: "short", day: "numeric", year: "numeric" }
) => {
  if (!value) return value
  return new Intl.DateTimeFormat("en-US", formatting).format(new Date(value))
}

// ** Returns short month of passed date
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
  const date = new Date(value)
  let formatting = { month: "short", day: "numeric" }

  if (toTimeForCurrentDay && isToday(date)) {
    formatting = { hour: "numeric", minute: "numeric" }
  }

  return new Intl.DateTimeFormat("en-US", formatting).format(new Date(value))
}

export const formatNumber = (formatValue, digits = 0) => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: digits,
    maximumFractionDigits: digits
  }).format(formatValue)
}

/**
 ** Return if user is logged in
 ** This is completely up to you and how you want to store the token in your frontend application
 *  ? e.g. If you are using cookies to store the application please update this function
 */
export const isUserLoggedIn = () => localStorage.getItem("userData")
export const getUserData = () => JSON.parse(localStorage.getItem("userData"))

/**
 ** This function is used for demo purpose route navigation
 ** In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 ** Please note role field is just for showing purpose it's not used by anything in frontend
 ** We are checking role just for ease
 * ? NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = (userRole) => {
  if (userRole.toLowerCase() === "admin") return "/dashboard"
  if (userRole.toLowerCase() === "client") return "/dashboard"
  return "/login"
}

// ** React Select Theme Colors
export const selectThemeColors = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: "#0C54951a", // for option hover bg-color
    primary: "#0C5495", // for selected option bg-color
    neutral10: "#0C5495", // for tags bg-color
    neutral20: "#ededed", // for input border-color
    neutral30: "#ededed" // for input hover border-color
  }
})

const createImage = (url) =>
  // eslint-disable-next-line implicit-arrow-linebreak
  new Promise((resolve, reject) => {
    const image = new Image()
    if (reject) console.log(reject)
    image.addEventListener("load", () => resolve(image))
    image.addEventListener("error", (error) => reject(error))
    image.setAttribute("crossOrigin", "anonymous")
    image.src = url
  })

function getRadianAngle(degreeValue) {
  return (degreeValue * Math.PI) / 180
}

export const rotateSize = (width, height, rotation) => {
  const rotRad = getRadianAngle(rotation)

  return {
    width:
      Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
    height:
      Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height)
  }
}

export const generateCroppedImageFile = async (
  imageSrc,
  pixelCrop,
  imageName,
  rotation = 0,
  flip = { horizontal: false, vertical: false }
) => {
  const image = await createImage(imageSrc)
  const canvas = document.createElement("canvas")
  const ctx = canvas.getContext("2d")

  if (!ctx) {
    return null
  }

  const rotRad = getRadianAngle(rotation)

  const { width: bBoxWidth, height: bBoxHeight } = rotateSize(
    image.width,
    image.height,
    rotation
  )

  canvas.width = bBoxWidth
  canvas.height = bBoxHeight

  ctx.translate(bBoxWidth / 2, bBoxHeight / 2)
  ctx.rotate(rotRad)
  ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1)
  ctx.translate(-image.width / 2, -image.height / 2)

  ctx.drawImage(image, 0, 0)

  const data = ctx.getImageData(
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height
  )

  canvas.width = pixelCrop.width
  canvas.height = pixelCrop.height

  ctx.putImageData(data, 0, 0)

  return new Promise((resolve) => {
    canvas.toBlob((file) => {
      const extension = imageName.substring(imageName.lastIndexOf("."))

      const updatedFile = new File(
        [file],
        `${imageName.split(".")[0]}${new Date().getTime()}${extension}`,
        { lastModified: new Date() }
      )
      // const updatedFile = new File([file], `${imageName.split('.')[0]}${new Date().getTime()}${extension}`, { lastModified: new Date() })
      resolve(updatedFile)
    }, `image/${imageName.substring(imageName.lastIndexOf("."))}`)
  })
}
