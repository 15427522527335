// ** React Imports
import { lazy, Suspense } from "react"

// ** Fallback loading component
const LoadingFallback = () => <div>Loading...</div>

// Lazy loading components without direct `.catch()` usage
const LoginCover = lazy(() => import("../../views/pages/authentication/Login"))
const RegisterBasic = lazy(() =>
  import("../../views/pages/authentication/RegisterCover")
)
const ForgotPasswordBasic = lazy(() =>
  import("../../views/pages/authentication/ForgotPasswordBasic")
)
const ResetPasswordCover = lazy(() =>
  import("../../views/pages/authentication/ResetPasswordCover")
)
const TwoStepsBasic = lazy(() =>
  import("../../views/pages/authentication/TwoStepsCover")
)
const AccountSetup = lazy(() =>
  import("../../views/pages/authentication/AccountSetup")
)

// ** Higher-order component to wrap components with Suspense
const withSuspense = (Component) => {
  return (
    <Suspense fallback={<LoadingFallback />}>
      <Component />
    </Suspense>
  )
}

const AuthenticationRoutes = [
  {
    path: "/login",
    element: withSuspense(LoginCover),
    meta: {
      layout: "blank",
      publicRoute: true,
      restricted: true
    }
  },
  {
    path: "/two-step",
    element: withSuspense(TwoStepsBasic),
    meta: {
      layout: "blank",
      publicRoute: true,
      restricted: true
    }
  },
  {
    path: "/register",
    element: withSuspense(RegisterBasic),
    meta: {
      layout: "blank",
      publicRoute: true,
      restricted: true
    }
  },
  {
    path: "/forgot-password",
    element: withSuspense(ForgotPasswordBasic),
    layout: "BlankLayout",
    meta: {
      layout: "blank",
      publicRoute: true,
      restricted: true
    }
  },
  {
    path: "/reset-password",
    element: withSuspense(ResetPasswordCover),
    meta: {
      layout: "blank"
    }
  },
  {
    path: "/setup-account/:id",
    element: withSuspense(AccountSetup),
    meta: {
      layout: "blank",
      publicRoute: true,
      restricted: false
    }
  }
]

export default AuthenticationRoutes
