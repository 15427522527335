// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

// ** Axios Imports
import axios from "axios"

export const getData = createAsyncThunk(
  "appAuditlog/getData",
  async (params) => {
    const response = await axios.get("/apps/auditlog/auditlogs", params)
    return {
      params,
      data: response.data.auditlogs,
      allData: response.data.allData,
      totalPages: response.data.total
    }
  }
)

export const deleteAuditlog = createAsyncThunk(
  "appAuditlog/deleteAuditlog",
  async (id, { dispatch, getState }) => {
    await axios.delete("/apps/auditlog/delete", { id })
    await dispatch(getData(getState().auditlog.params))
    return id
  }
)

export const appAuditlogSlice = createSlice({
  name: "appAuditlog",
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: []
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getData.fulfilled, (state, action) => {
      state.data = action.payload.data
      state.allData = action.payload.allData
      state.total = action.payload.totalPages
      state.params = action.payload.params
    })
  }
})

export default appAuditlogSlice.reducer
